import i18n from "@/libs/i18n";
import Vue from "vue";
import store from "@/store";

/* eslint-disable import/prefer-default-export */
/**
 * Returns the collective's URL.
 * @param {*} collective The collective
 * @returns The URL.
 */
export function getCollectiveUrl(collective, params = "") {
  if(!collective){
    return null;
  }
  return (
    process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
      "{subdomain}",
      collective.subdomain || "app",
    ).replace("{slug}", collective.slug) + params
  );
}

export function selectLangFromCommunity(community) {
  const lang = Vue.$cookies.get("i18n_redirected") || i18n.locale || "en"; 
  // console.log('selectLangFromCommunity', lang);

  const communityIncludesLang = community?.availableLanguages?.includes(lang);
  // console.log('communityIncludesLang', communityIncludesLang);

  const i18nRedirected = Vue.$cookies.get("i18n_redirected");
  // console.log('i18nRedirected', i18nRedirected);

  if (community && !communityIncludesLang && !i18nRedirected) {
    // console.log('selectLangFromCommunity - no lang and no redirect');
    const langCodeInAvailableLanguages = community.availableLanguages?.includes(community.languageCode);
    // console.log('langCodeInAvailableLanguages', langCodeInAvailableLanguages);

    const newLocale = langCodeInAvailableLanguages ? community.languageCode :community.availableLanguages?.[0];
    // console.log('newLocale', newLocale);

    // Set the new locale
    store.dispatch("setLocale", newLocale);

    // Update the i18n locale
    i18n.locale = newLocale;
    Vue.$cookies.set("i18n_redirected", i18n.locale);

  } else {
    // If the lang is not in the available languages, set the lang to the first available language
    // console.log('selectLangFromCommunity - lang is not in the available languages');
    store.dispatch("setLocale", lang);

    return i18n.locale || "en";
  }
}
