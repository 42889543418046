/**
 * Smart Badges Plugin
 * 
 * This plugin provides automatic text color contrast for badges based on background color.
 * It offers multiple ways to use the functionality:
 * 
 * 1. CSS class: Add 'badge-smart-text' class to any badge
 * 2. Directive: Use v-badge-smart-text directive on badge components
 * 3. Mixin: Import and use the badges mixin for programmatic control
 */

import BadgeSmartText from '@/@core/directives/badge-smart-text';
import BadgesMixin from '@/@core/mixins/ui/badges';

export default {
  install(Vue) {
    // Register the directive
    Vue.directive('badge-smart-text', BadgeSmartText);
    
    // Make the mixin available globally
    Vue.mixin({
      methods: {
        $smartBadge(variant = 'primary', isPill = false) {
          return {
            variant,
            class: `badge-smart-text ${isPill ? 'pill' : ''}`
          };
        }
      }
    });
    
    // Create a component factory method for creating smart badges
    Vue.prototype.$createSmartBadge = (props = {}) => {
      return {
        component: 'b-badge',
        class: 'badge-smart-text',
        ...props
      };
    };
  }
};

// Export the mixin for individual component imports
export { BadgesMixin }; 