/**
 * Badge Smart Text Directive
 * 
 * Automatically adds the badge-smart-text class to b-badge components
 * to ensure text has proper contrast against the background color.
 * 
 * Usage: <b-badge v-badge-smart-text variant="primary">Text</b-badge>
 */

export const BadgeSmartText = {
  bind(el) {
    if (el) {
      el.classList.add('badge-smart-text');
    }
  }
};

export default BadgeSmartText; 